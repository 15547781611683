































































































import {Component, Vue} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import Vuelidate from 'vuelidate';
import {requiredIf} from 'vuelidate/lib/validators';
import moment from 'moment';
import {ModelDataInterface} from '@/types/userDataInterface';
import {vxm} from '@/store';

Vue.use(Vuelidate);

@Component({
  components: {Modal},
  validations: {
    date: {
      selectedYear: {
        required: requiredIf(function(this) {
          return !!(this.date.selectedMonth || this.date.selectedDay);
        }),
      },
      selectedMonth: {
        required: requiredIf(function(this) {
          return !!(this.date.selectedYear || this.date.selectedDay);
        }),
      },
      selectedDay: {
        required: requiredIf(function(this) {
          return !!(this.date.selectedYear || this.date.selectedMonth);
        }),
      },
    },
  },
})
export default class EditModelBankAccount extends Vue {
  user: ModelDataInterface = vxm.user.data;
  showModal = false;
  isRequestSend = false;
  form = {
    bankFirstName: this.user.bankFirstName,
    bankDateofBirth: this.user.bankDateofBirth,
    bankSsn: this.user.bankSsn,
    bankName: this.user.bankName,
    bankAccount: this.user.bankAccount,
    bankRounding: this.user.bankRounding,
    bankSwiftCode: this.user.bankSwiftCode,
    bankBankAddress: this.user.bankBankAddress,
    bankCountry: this.user.bankCountry,
    bankState: this.user.bankState,
    bankCity: this.user.bankCity,
    bankAddress: this.user.bankAddress,
    bankZip: this.user.bankZip,
  };
  activeMonth = !!this.user.bankDateofBirth;
  activeDay = !!this.user.bankDateofBirth;
  activeYear = !!this.user.bankDateofBirth;
  birthDate = moment(this.user.bankDateofBirth);
  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  date = {
    selectedMonth: this.user.bankDateofBirth ? this.months[this.birthDate.month()] : '',
    selectedDay: this.user.bankDateofBirth ? this.birthDate.date() : '',
    selectedYear: this.user.bankDateofBirth ? this.birthDate.year() : '',
  };

  get years() {
    const year = new Date().getFullYear();
    return Array.from({length: year - 1950}, (value, index) => 1951 + index);
  }

  save() {
    this.$v.date.$touch();

    if (!this.$v.date.$invalid && !this.isRequestSend) {
      this.isRequestSend = true;

      if (this.date.selectedYear && this.date.selectedMonth && this.date.selectedDay) {
        this.form.bankDateofBirth = moment()
          .year(+this.date.selectedYear)
          .month(this.date.selectedMonth)
          .date(+this.date.selectedDay)
          .format();
      }

      const data = {user: {...this.form}};
      vxm.user
        .updateProfile(data)
        .then(() => {
          this.showModal = false;
        })
        .catch((error) => {
          return error;
        })
        .then(() => {
          this.isRequestSend = false;
        });
    }
  }
}
